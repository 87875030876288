import React from "react"
import { LargeFeatureUnitProps } from "./largeFeatureUnit"
import { joinClassNames } from "@common/lib/util"
import ImageWrapper from "@common/imageWrapper"
import { useInView } from "react-intersection-observer"
import LinkButton from "@common/buttons/linkButton"
import { Header } from "@common/header/headerUnit"
import { motion } from "framer-motion"
interface FeatureWithSideImageProps extends LargeFeatureUnitProps {
  imageUrl: string
  imageOverflow?: boolean
  className?: string
}

export default function FeatureWithSideImage({
  imageUrl,
  imgLeft = false,
  header,
  description,
  shortHeader,
  actionText,
  actionLink,
  imageOverflow = true,
  className,
  headerClass,
  shortHeaderClass,
  showBar,
}: FeatureWithSideImageProps) {
  const contentColumnOrder = imgLeft ? "" : "lg:order-2"
  const imagePositionClass = imgLeft ? "object-right" : "object-left"
  const imageContainerClass = joinClassNames(
    "hidden sm:block mt-8 sm:mt-12 lg:mt-0 relative h-80 lg:h-full w-full"
    // imgLeft ? "pl-4 lg:-mr-48 sm:pl-6 md:-mr-16" : "pr-4 -ml-48 sm:pr-6 md:-ml-16"
  )

  const imageClass = joinClassNames(
    imageOverflow
      ? "lg:absolute lg:h-full lg:w-auto lg:max-w-none object-contain object-left"
      : "lg:h-full object-contain lg:max-w-none",
    imagePositionClass
  )

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "-65% 0% -35% 0%",
    triggerOnce: true,
  })

  const containerVariants = {
    hidden: {
      opacity: 0,
      x: imgLeft ? -100 : 100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.2,
        ease: "easeOut",
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  }

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  }

  const buttonVariants = {
    hidden: {
      opacity: 0,
      scale: 0.9,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.4,
        ease: "easeOut",
        delay: 0.6,
      },
    },
  }

  return (
    <div
      className={joinClassNames("relative bg-background overflow-hidden", className)}
    >
      <div className="lg:mx-auto lg:max-w-8xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24">
        <div
          className={joinClassNames(
            "px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0",
            contentColumnOrder
          )}
        >
          <>
            <div className="my-3">
              <Header.ShortHeader
                variant="h3"
                alignment="left"
                className={shortHeaderClass}
                asChild={React.isValidElement(shortHeader)}
              >
                {shortHeader}
              </Header.ShortHeader>
            </div>
            <motion.div
              ref={ref}
              variants={containerVariants}
              initial="hidden"
              animate={inView ? "visible" : ""}
            >
              <Header variant="h3" alignment="left">
                <motion.div variants={itemVariants}>
                  <Header.MainHeader
                    asChild={React.isValidElement(header)}
                    className={headerClass}
                  >
                    {header}
                  </Header.MainHeader>
                </motion.div>

                <div className="relative min-h-80 w-full sm:hidden">
                  <ImageWrapper
                    className="object-contain"
                    src={imageUrl}
                    fill
                    alt="Customer profile user interface"
                  />
                </div>

                <motion.div variants={itemVariants}>
                  <Header.Description color="primary" asChild>
                    <p> {description}</p>
                  </Header.Description>
                </motion.div>
              </Header>

              {actionText && actionLink && (
                <motion.div className="mt-6" variants={buttonVariants}>
                  <LinkButton href={actionLink} variant="secondary" size="md">
                    {actionText}
                  </LinkButton>
                </motion.div>
              )}
              {showBar && (
                <motion.div
                  className="w-full h-0.5 bg-muted rounded-full mt-12 overflow-hidden"
                  variants={itemVariants}
                >
                  <motion.div
                    className="h-full bg-primary rounded-full"
                    initial={{ width: 0 }}
                    animate={inView ? { width: "100%" } : { width: 0 }}
                    transition={{
                      duration: 2,
                      ease: [0, 0.71, 0.2, 1.01],
                      delay: 0.8,
                    }}
                  />
                </motion.div>
              )}
            </motion.div>
          </>
        </div>
        <div className={imageContainerClass}>
          <ImageWrapper
            className={imageClass}
            src={imageUrl}
            alt="Customer profile user interface"
          />
        </div>
      </div>
    </div>
  )
}
